const messages = {
	aboutMe: '私について',
	backToBlog: 'ブログに戻る',
	blog: 'ブログ',
	categories: "種別",
	competences: '能力',
	contact: '私に連絡する!',
	education: '学校教育',
	email: 'はいしんさき',
	emptyCategory: 'このカテゴリーに記事はありません。',
	experiences: '経験',
	firstName: 'なまえ',
	footerText:
		'このサイトは100％クッキーフリーを保証しています! CAYET Sébastien （自身）がAngular 19で開発。',
	from: '',
	greatings: 'こんにちは!',
	greatingsDescription: '私はセバスチャン. ウェブデベロッパーになって4年。',
	greatingsOccupation: '今, リヨンに, 私は Klanik の Angular テックリード。',
	home: 'ウェルカムページ',
	inThisArticle: 'この記事では',
	knowledge: '知ること',
	languages: '国語',
	languagesText:
		'異文化を吸収するためには、異なる言語を知ることが重要だと思います。フランス語は私の母語ですが、仕事ではほとんど英語を使っています。学校ではスペイン語を学びました。今は日本語を勉強しています。',
	lastName: 'みょうじ',
	latestArticles: "最近の記事",
	message: 'ぶんめん',
	metaDescription: 'セバスティアン・カイエの開発者履歴書を探索し、大規模なアプリケーションの優れたユーザーエクスペリエンスを提供するためのスキルと専門知識を紹介します：Web開発、UXデザイン、アジャイルマネジメント、仕様書の作成など！',
	metaTitle: 'セバスティアン・リード・フロントエンド・デベロッパー',
	myTastes: '私の趣味嗜好',
	myStyle: '私のプレイスタイル',
	mailFormText: '以下のフォームに必要事項を記入してメールを送ってください。',
	mailFormMandatoryErrorMessage: 'このフィールドは必須です。',
	mailFormEmailErrorMessage: '有効なメールアドレスを入力してください。',
	mailFormErrorMessage:
		'少なくとも一度のフィールドが無効なため、フォームを送信することができませんでした。',
	noArticleFound: 'このアドレスに記事はありません',
	personalInformations: '個人情報',
	portefolio: 'ポートフォリオ',
	programming: 'コード化言語',
	resumeTitle: '私の履歴書',
	scrollThrough: 'もっと知りたいですか？私のウェブサイトをご覧ください',
	seeAll: 'すべて見る',
	seeExperiences: '私の経験をよむ',
	seeCompetences: '私の能力をよむ',
	seeResume: '履歴書',
	sendMail: '交信',
	send: '送信する',
	siteDescription: '',
	subject: 'けんめい',
	subtitle: 'はじめまして！私はウェブ開発者です。よろしく。',
	title: 'CAYET Sébastien',
	theory: '理論',
	to: 'から',
	toAfter: 'まで',
	tooling: 'ツール',
	visit: '見る',
};

export default messages;
