const messages = {
	aboutMe: 'About me',
	backToBlog: 'Back to the blog',
	blog: 'Blog',
	categories: "Categories",
	competences: 'Competences',
	contact: 'Contact me !',
	education: 'Education',
	email: 'E-mail',
	emptyCategory: 'No article in this category.',
	experiences: 'Experiences',
	firstName: 'First Name',
	footerText:
		'This site is guaranteed 100% cookie-free! Developed by Sébastien CAYET (himself) with Angular 19.',
	from: 'From',
	greatings: 'Greatings!',
	greatingsDescription:
		"I'm Sébastien, a Lead Web Developer.",
	greatingsOccupation: 'Currently an Angular Techlead for Klanik, in Lyon.',
	home: 'Home',
	inThisArticle: 'In this article',
	knowledge: 'Knowledge',
	languages: 'Languages',
	languagesText:
		"I think that knowing different languages is important when you want to issimilate different cultures. French is my mother tongue, but I mostly use English for working. I learnt Spanish during my studies. Today, I'm learning Japanese.",
	lastName: 'Last Name',
	latestArticles: "Latest articles",
	message: 'Message',
	metaDescription: 'Explore the developer resume of Sébastien Cayet, showcasing his skills and expertise to deliver outstanding user experiences for large scale applications : Web development, UX Design, Agile Management, Specification Writng and more !',
	metaTitle: 'Sébastien Cayet - Lead Frontend Software Engineer',
	myTastes: 'My tastes',
	myStyle: 'My style',
	mailFormText: 'Fill the following form to send me a mail',
	mailFormMandatoryErrorMessage: 'This field is mandatory.',
	mailFormEmailErrorMessage: 'Give a valid e-mail address.',
	mailFormErrorMessage:
		'Your form could not be submitted because at least once field is invalid.',
	noArticleFound: 'No article found at this address',
	personalInformations: 'Personal Informations',
	portefolio: 'Portefolio',
	programming: 'Coding languages',
	resumeTitle: 'My Resume',
	scrollThrough: 'Wanna know more about me ?   ~   Please scroll through!',
	seeAll: 'See all',
	seeExperiences: 'Read my experiences',
	seeCompetences: 'Read my competences',
	seeResume: 'My resume',
	sendMail: 'Contact me',
	send: 'Send',
	siteDescription: '',
	subject: 'Subject',
	subtitle: "Hello! I'm a web developer!",
	title: 'Sébastien CAYET',
	theory: 'Theory',
	to: 'to',
	tooling: 'Tools',
	visit: 'Visit',
};

export default messages;
